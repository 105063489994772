import "./App.css";
import Background from "./component/Background"

function App() {
  return (
    <>
      <Background/>
      <section className="grid place-content-center h-screen justify-items-center">
      <h1 className="text-white text-2xl sm:text-3xl md:text-4xl xl:mt-36" >Site Is Under</h1>
      <h1 className="text-white text-2xl sm:text-3xl md:text-4xl">Construction</h1>
      </section>
    </>
  );
}

export default App;
