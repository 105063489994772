import React from 'react'
import './background.css'

export default function background() {
  return (
    <div>
      {/* <div className="shape absolute left-0 -z-10"></div>
      <div className="shape absolute right-0 -z-10"></div> */}
       <svg xmlns="http://www.w3.org/2000/svg" className='absolute w-full h-full -z-10' width="986" height="607" viewBox="0 0 986 607" fill="none">
      <g filter="url(#filter0_f_6_5)">
      <path d="M736 493C736 627.205 627.205 448 493 448C358.795 448 250 627.205 250 493C250 358.795 358.795 250 493 250C627.205 250 736 358.795 736 493Z" fill="#4E00F3"/>
      </g>
      <defs>
      <filter id="filter0_f_6_5" x="0" y="0" width="986" height="792.863" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_6_5"/>
      </filter>
      </defs>
      </svg>
      {/* <svg xmlns="http://www.w3.org/2000/svg" className='absolute w-full h-full -z-10' width="1440" height="857" viewBox="0 0 1440 857" fill="none">
  <g filter="url(#filter0_f_6_5)">
    <path d="M945 743C945 877.205 836.205 698 702 698C567.795 698 459 877.205 459 743C459 608.795 567.795 500 702 500C836.205 500 945 608.795 945 743Z" fill="#4E00F3"/>
  </g>
  <defs>
    <filter id="filter0_f_6_5" x="-41" y="0" width="1486" height="1292.86" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="250" result="effect1_foregroundBlur_6_5"/>
    </filter>
  </defs>
</svg> */}
    </div>
  )
}
